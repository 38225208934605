import React from "react"
import { Link } from "gatsby"
import { FaAlignRight } from "react-icons/fa"
import NavLinks from "../../constants/navLinks"
import logo from "../../assets/logo.png"
import "./navbar.scss"

const Layout = ({ toggleSidebar }) => {
    return (
        <nav className="nav">
            <div className="nav__container">
                <div className="nav__header">
                    <Link to="/">
                        <img src={logo} alt="logo" height="50" />
                    </Link>
                    <button
                        type="button"
                        className="btn--toggle"
                        onClick={toggleSidebar}
                    >
                        <FaAlignRight></FaAlignRight>
                    </button>
                </div>
                <NavLinks styleClass="nav__links"></NavLinks>
            </div>
        </nav>
    )
}

export default Layout
