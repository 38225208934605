import React from "react"
import NavLinks from "../../constants/navLinks"
import { FaTimes } from "react-icons/fa"
import "./sidebar.scss"

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <aside className={`sidebar u-bg-grey ${isOpen ? "show-sidebar" : ""}`}>
            <button className="btn--close" onClick={toggleSidebar}>
                <FaTimes />
            </button>
            <div className="sidebar__container">
                <NavLinks
                    styleClass={`${isOpen ? "sidebar__links" : ""}`}
                    toggleSidebar={toggleSidebar}
                />
            </div>
        </aside>
    )
}

export default Sidebar
