import React from "react"
import { FaGithubSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa"

const data = [
    {
        id: 1,
        icon: <FaGithubSquare className="icon--social"></FaGithubSquare>,
        url: "https://github.com/khusharth/",
    },
    {
        id: 2,
        icon: <FaLinkedin className="icon--social"></FaLinkedin>,
        url: "https://www.linkedin.com/in/khusharth/",
    },
    {
        id: 3,
        icon: <FaTwitterSquare className="icon--social"></FaTwitterSquare>,
        url: "https://twitter.com/khusharth19",
    },
]

const links = data.map(link => {
    return (
        <li key={link.id}>
            <a href={link.url} className="social__link">
                {link.icon}
            </a>
        </li>
    )
})

export default ({ styleClass }) => {
    return (
        <ul className={`social__links ${styleClass ? styleClass : ""}`}>
            {links}
        </ul>
    )
}
