import React from "react"
import SocialLinks from "../../constants/socialLinks"
import logo from "../../assets/logo-footer.svg"
import "./footer.scss"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__logo"><img src={logo} alt="footer logo" height='70' /></div>
            <SocialLinks styleClass="footer__links"></SocialLinks>
            <h4>Made with &#10084; by Khusharth Patani</h4>
        </footer>
    )
}

export default Footer
